$primary: #68b963;
$primary_main_400: #67aa4a;
$secondary_main_500: #505050;
$secondary_main_400: #999999;
$secondary_main_300: #b3b3b3;
$secondary_main_50: #f7f7f7;
$secondary_main_100: #cccccc;
$warn_main_400: #ffab00;
$warn_main_50: #fff7e5;
$error_main_400: #bf2600;
$error_main_50: #ffefeb;
$success_main_400: #338c1c;
$success_main_50: #f2ffef;
$primary_blue_400: #1665db;
$primary_blue_50: #dee9fa;
$primary_blue_40: #f5f9ff;
$primary_blue_30: #eaf4ff;
$school_primary_blue_400: #2a2f76;
$school_primary_blue_100: #0e7dff;
$school_warn_400: #fb9a15;
$school_warn_100: #ffedd4;
$school_success_400: #338c1c;
$school_success_100: #c5dfb4;
$school_error_100: #bf2600;
$school_grey_100: #636363;
$school_bg_color: #f3fbff;
$text_color_3b3b3b: #3b3b3b;
$text_color_414141: #414141;
$text_color_032954: #032954;
$color_white: #fff;
$txt_color_061058: #061058;
$txt_color_1fb2e2: #1fb2e2;
$text_color_061058: #061058;
$white: #ffffff;
$black_primary: #191919;
$black_secondary: #222222;
$text_primary: #061058;
$text_color_f5f6fa: #f5f6fa;
$red: #e91e63;
$color_1f79e2: #1f79e2;
$color_1fb2e2: #1fb2e2;
$text_color_707070: #707070;
$color_404244: #404244;
$color_061058: #061058;
$color_414141: #414141;
$color_3b3b3b: #3b3b3b;
$color_FFD2D2: #ffd2d2;
$color_D8000C: #d8000c;
$color_0000001f: #0000001f;
$color_707070: #707070;
$color_999999: $secondary_main_400;
$color_eee: #eee;
$color_73a7e4: #73a7e4;
$color_39c75b: #39c75b;
$color_39C75B: #39c75b;
$color_fdfdfd: #fdfdfd;
$color_f5f6fa: #f5f6fa; //bg color
$color_67AA4A: #67aa4a;
$app_bg: #f3f3f3;
$color_1665D8: #1665d8;
$color_191919: #191919;
$box_shadow: #00000014;
$danger: #d8000c;
$color_000000: #000000;
$warn: rgb(198, 198, 0);
$color_FFAB00: #ffab00;
$color_FFF7E5: #fff7e5;
$color_F7F7F7: #f7f7f7;
$color_CCC: #ccc;
$color_505050: #505050;
$color_338C1C: #338c1c;
$color_F2FFEF: #f2ffef;
$color_white_main: #fff;
$jangriha_primary_400: #0482AE;
$jangriha_primary_50: #e3eafe;
$jangriha_primary_blue_400: #27006d;
$jangriha_primary_sky_color: #05a3d9;
$jangriha_secondary_sky_color: #0482AE;
$jangriha_primary_sky_color_100: #f0fbff;
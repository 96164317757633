/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");
@import "src/app/common/styles/index";
@import "~@fortawesome/fontawesome-free/css/all.min.css";

html,
body {
  height: 100vh;
}

html {
  font-size: 16px; /* Default font size for smaller screens */
}

.mat-form-field .mat-input-element {
  font-size: 16px;
}

@media (min-width: 576px) { /* Small screens */
  html {
    font-size: 12px;
  }
  .mat-form-field .mat-input-element {
    font-size: 12px;
  }
}

@media (min-width: 768px) { /* Medium screens */
  html {
    font-size: 12px;
  }
  .mat-form-field .mat-input-element {
    font-size: 12px;
  }
  .mat-icon {
    font-size: 24px;
  }
}

@media (min-width: 992px) { /* Large screens */
  html {
    font-size: 16px;
  }
  .mat-form-field .mat-input-element {
    font-size: 16px;
  }
  .mat-icon {
    font-size: 24px;
  }
}

@media (min-width: 1200px) { /* Extra large screens */
  html {
    font-size: 14px;
  }
  .mat-form-field .mat-input-element {
    font-size: 14px;
  }
  .mat-icon {
    font-size: 20px;
  }
}

@media (min-width: 1400px) { /* Extra extra large screens */
  html {
    font-size: 16px;
  }
  .mat-form-field .mat-input-element {
    font-size: 16px;
  }
  .mat-icon {
    font-size: 24px;
  }
}

body {
  margin: 0;
  // font-family: "Lato", sans-serif !important;
  font-family: "Poppins", sans-serif !important;
}
// font-size: .75rem;
.mat-form-field-appearance-outline .mat-form-field-outline-start {
  background-color: black !important;
}
.mat-form-field-appearance-outline .mat-form-field-outline-gap {
  background-color: black !important;
}
.mat-form-field-appearance-outline .mat-form-field-outline-end {
  background-color: black !important;
}
.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper{
  padding: 0 !important;
}
.mat-mdc-form-field-error{
  color: $error_main_400 !important;
}
.mat-form-field-appearance-outline:not(.mat-form-field-disabled)
  .mat-form-field-flex:hover
  .mat-form-field-outline {
  opacity: 1 !important;
}
.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.7em 0 0.7em 0 !important;
  top: -0.25em !important;
}

.mat-form-field-appearance-outline.mat-form-field-outline.mat-input-element {
  background-color: white !important;
}

.mat-form-field-flex {
  background-color: white !important;
}

// .mat-form-field-appearance-outline {
//   padding-top: 6px;
// }

.mat-icon {
  height: auto !important;
  width: auto !important;
}

.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #1fb2e2 !important;
  --mdc-fab-icon-color: #fff !important;
  --mat-mdc-fab-color: #fff;
  color: #ffffff;
  background-color: #1fb2e2;
}

a {
  text-decoration: none;
  color: black;
  text-transform: capitalize;
}

.link-active {
  color: $primary_main_400;
}

.mat-button-toggle-checked {
  background-color: $primary_main_400 !important;
  a {
    color: white !important;
  }
  // height: 27px !important;
  border-radius: 4px !important;
}
.mat-focus-indicator {
  // width: 108px;
  // height: 27px !important;
  border-radius: 4px !important;
  .mat-button-toggle-label-content {
    line-height: 2 !important;
  }
}

.mat-drawer-container {
  padding-top: 46px;
  height: -webkit-fill-available !important;
}

.mat-button-toggle {
  color: black;
  // margin-left: 4px;
  .mat-button-toggle-button {
    // margin-right: 25px;
  }
}

.mat-badge-content {
  background: $primary_main_400;
  color: white;
}

// .mat-drawer.mat-drawer-side {
//   z-index: 2;
//   position: fixed;
//   overflow: hidden;
//   top: 46px;
//   left: 0;
// }

// .mat-drawer-transition .mat-drawer-content {
//   margin-left: 95px;
// }

.btn {
  width: fit-content;
  padding: 8px 16px 8px 16px;
  // box-shadow: 0px 0px 20px 0px #00000014;
  color: white;
  font-weight: 400;
  cursor: pointer;
  border: none;
  @include responsive-fourteen-font-size(14px);
  font-family: "Lato", sans-serif !important;
  font-family: "Poppins", sans-serif !important;
}
.btn-primary {
  background-color: $primary_main_400;
  &:hover {
    background-color: #306a16;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #999999;
  }
}
.btn-secondary {
  background-color: transparent;
  color: $primary_main_400;
  border: 1px solid $primary_main_400;
  &:hover {
    color: #306a16;
    border: 1px solid #306a16;
  }
  &:disabled {
    cursor: not-allowed;
    color: #cccccc;
    border: none;
  }
}
.btn-elevated {
  box-shadow: 0px 0px 20px 0px #00000014;
  background-color: #ffffff;
  color: $primary_main_400;
  &:hover {
    color: #306a16;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #a2a2a2;
    color: #cccccc;
  }
}

.btn-text {
  background-color: transparent;
  color: $primary_main_400;
  border: 1px solid $primary_main_400;
  &:hover {
    color: #306a16;
    // box-shadow: 0px 0px 20px 0px #00000014;
  }
  &:disabled {
    cursor: not-allowed;
    color: #cccccc;
  }
}

#favicon {
  width: 128px;
  height: 128px;
}

.btn-danger {
  color: $error_main_400;
  border: 1px solid $error_main_400;
  background-color: white;
  &:hover {
    color: rgb(200, 0, 0);
    box-shadow: 0px 0px 20px 0px #00000014;
  }
  &:disabled {
    cursor: not-allowed;
    color: #cccccc;
  }
}

.btn-warn {
  color: $warn_main_400;
  border: 1px solid $warn_main_400;
  background-color: white;
  &:hover {
    color: rgb(175, 114, 1);
    box-shadow: 0px 0px 20px 0px #00000014;
  }
  &:disabled {
    cursor: not-allowed;
    color: #cccccc;
  }
}

.btn-icons {
  cursor: pointer;
}

.mat-mdc-menu-panel.notification-icon {
  // min-width: 458px !important;
  max-width: 439px !important;
}

.threshold_true {
  border: 1px solid $primary;
  color: $primary;
  display: flex;
  justify-content: center;
  align-items: center;
}

.threshold_false {
  border: 1px solid $danger;
  color: $danger;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cdk-overlay-pane.my-dialog {
  position: relative !important;
}
.close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  line-height: 14px;
  min-width: auto;
  float: right;
  border: none;
  background-color: white;
}

.snackbar-success {
  background-color: $success_main_50;
  border: 1px solid $success_main_400;
  color: $success_main_400;
}

.snackbar-error {
  background-color: $error_main_50;
  border: 1px solid $error_main_400;
  color: $error_main_400;
}

.mdc-text-field--disabled .mdc-text-field__input {
  color: $secondary_main_400 !important;
  cursor: not-allowed !important;
}

.mdc-text-field--disabled .mdc-floating-label {
  color: $secondary_main_400 !important;
  cursor: not-allowed !important;
}

.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: $secondary_main_400 !important;
}

.mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: none !important;
}

.mat-accordion .mat-expansion-panel:not(.mat-expanded),
.mat-accordion .mat-expansion-panel:not(.mat-expansion-panel-spacing) {
  border-radius: 4px !important;
}

.mdc-text-field--invalid .mdc-text-field__input {
  caret-color: $error_main_400 !important;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: $error_main_400 !important;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled)
  .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled)
  .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled)
  .mdc-notched-outline__trailing {
  border-color: $error_main_400 !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__trailing {
  border-color: $primary_main_400 !important;
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: $primary_main_400 !important;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #000000de;
}

.mat-mdc-dialog-actions {
  justify-content: end !important;
}

.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:checked
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-color: $primary_main_400 !important;
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none !important;
}

.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled
  + .mdc-radio__background
  .mdc-radio__inner-circle {
  border-color: $primary_main_400 !important;
}

.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:not(:checked)
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-color: $secondary_main_500 !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
  .mdc-list-item__primary-text {
  color: $primary_main_400 !important;
}

.mat-mdc-row,
.mdc-data-table__content {
  @include responsive-sixteen-font-size(16px);
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
}

.mat-mdc-header-cell {
  @include responsive-sixteen-font-size(16px);
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  border-bottom-color: transparent !important;
  border-bottom: none !important;
}

.mat-button-toggle .mat-pseudo-checkbox {
  display: none !important;
}